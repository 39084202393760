import { ApolloError } from '@apollo/client'
import {
  Avatar,
  Button,
  Center,
  Flex,
  Image,
  useMediaQuery,
  useToast,
  VStack,
  Link as ChakraLink,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  Portal,
  IconButton,
  Heading,
  Spinner,
  Menu as DD,
  MenuItem as DDItem,
  MenuButton as DDButton,
  MenuList as DDList
} from '@chakra-ui/react'
import { Text } from '../../typography'
import styled from '@emotion/styled'
import SideBarButton from 'components/SideBar/SideBarButton'
import { useAuthContext } from 'context/AuthProvider/index'
import { motion } from 'framer-motion'
import {
  Notifications,
  NotificationsDocument,
  useNotificationsLazyQuery,
  useMarkAsReadMutation,
  NewsResponse,
  useGetArticleTypeOrderQuery
} from 'generated/graphql'
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { color, ColorProps, height, HeightProps, space, SpaceProps } from 'styled-system'
import { theme, images } from 'theme'
import {
  ERROR_TOAST,
  navSaHorseRacingItems,
  navBettingItems,
  navBreedersItems,
  responsiveWidth,
  navMediaItems,
  saHorseRacingRoutes,
  mediaLibraryRoutes,
  bettingRoutes,
  breedersRoutes,
  authBreedersRoutes,
  navAuthBreedersItems,
  authSaHorseRacingRoutes,
  navAuthSaHorseRacingItems,
  authBettingRoutes,
  authMediaLibraryRoutes,
  navAuthBettingItems,
  navAuthMediaItems
} from '../../constants'
import { useAppContext } from '../../context/AppProvider'
import { Card, RevealFlex } from 'components'
import { ChevronDown, X } from 'react-feather'
import _ from 'lodash'

type PublicHeaderProps = ColorProps & {
  color?: string
  size?: number
  id?: string
  open?: boolean
  getLoggedInUser?: () => { name?: string; id: string }
}

type PublicHeaderContProps = SpaceProps &
  ColorProps &
  HeightProps & {
    color?: string
    open?: boolean
  }

const PublicHeaderCont = styled(motion.div)<PublicHeaderContProps>`
  ${space};
  ${color};
  ${height};
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '64px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const emptyData: [] = []

type MenuItemProps = {
  navUrl: string

  navName: string
}

const PublicHeader: React.FC<PublicHeaderProps> = ({ ...rest }) => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  const { drawerOpen, toggleDrawer } = useAppContext()
  const [notificationOpen, setNotificationOpen] = React.useState(false)
  const toast = useToast()

  const history = useHistory()

  const [notifications, setNotifications] = React.useState<Array<Notifications>>([])
  const [currentTab, setCurrentTab] = React.useState<NewsResponse | undefined>()

  const { isAuthenticated } = useAuthContext()

  const { location, push } = useHistory()
  const { data, loading: gettingOrder } = useGetArticleTypeOrderQuery({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST })
  })

  const [getNotifications, { loading }] = useNotificationsLazyQuery({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: (data) => {
      setNotifications(data.notifications as Array<Notifications>)
    },
    fetchPolicy: 'cache-and-network'
  })

  const [markAsRead, { loading: markAsReadLoading }] = useMarkAsReadMutation({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: (data) => {
      setNotifications(data.markAsRead as Array<Notifications>)
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: NotificationsDocument }],
    update(cache, { data }) {
      cache.modify({
        fields: {
          myNotifications() {
            const newNotificationRef = cache.writeQuery({
              query: NotificationsDocument,
              data
            })

            return newNotificationRef
          }
        }
      })
    }
  })

  const newsCategories = _.orderBy(data?.getArticleTypeOrder || emptyData, 'menuOrder')

  const mediaLibraryPath = mediaLibraryRoutes.includes(location.pathname)
  const breedersPath = breedersRoutes.includes(location.pathname)
  const bettingPath = bettingRoutes.includes(location.pathname)
  const saHorseRacingPath = saHorseRacingRoutes.includes(location.pathname)
  const authBreedersPath = authBreedersRoutes.includes(location.pathname)
  const authSaHorseRacingPath = authSaHorseRacingRoutes.includes(location.pathname)
  const authBettingPath = authBettingRoutes.includes(location.pathname)
  const authMediaLibraryPath = authMediaLibraryRoutes.includes(location.pathname)
  React.useEffect(() => {
    if (newsCategories.length) {
      const hash = (location.hash || '').substring(1)
      setCurrentTab(newsCategories.find((item) => item.orderId === hash) as NewsResponse)
    }
  }, [location.hash, newsCategories])

  const SubMenu = () => {
    if (isTabletOrMobile && location.pathname !== '/news') {
      return null
    }

    if (gettingOrder) {
      return <></>
    }

    return (
      <Flex width={isTabletOrMobile ? '50px' : '100%'}>
        {isTabletOrMobile ? (
          <DD>
            <DDButton
              as={Button}
              rightIcon={<ChevronDown />}
              _hover={{ bg: 'gray.200' }}
              _expanded={{ bg: 'gray.100' }}
              _focus={{ boxShadow: 'none' }}
            >
              <Text fontSize="sm" fontWeight="bold" color="blue.500">
                {currentTab?.title}
              </Text>
            </DDButton>
            <DDList>
              {newsCategories.map((navtab) => {
                return (
                  <DDItem key={navtab.orderId}>
                    <ChakraLink variant="link" width="100%" href={`#${navtab?.orderId}`}>
                      <Text fontSize="sm" fontWeight="bold" color="blue.500">
                        {navtab.title}
                      </Text>
                    </ChakraLink>
                  </DDItem>
                )
              })}
            </DDList>
          </DD>
        ) : (
          newsCategories.map((navtab, index) => {
            return (
              <ChakraLink
                variant="link"
                width="auto"
                mr={index === newsCategories.length - 1 ? 0 : 3}
                key={navtab.orderId}
                href={`#${navtab?.orderId}`}
                whiteSpace="nowrap"
              >
                <Text fontSize="sm" fontWeight="bold" color="blue.500">
                  {navtab.title}
                </Text>
              </ChakraLink>
            )
          })
        )}
      </Flex>
    )
  }

  const MenuItem = ({ navName, navUrl }: MenuItemProps) => (
    <ChakraLink
      href={navUrl}
      whiteSpace="nowrap"
      isExternal={navName === 'TabGold' || navName === 'Track and Ball'}
    >
      <Text mr={2} fontSize={['sm', 'md', 'lg', '14px']} fontWeight="bold" color="blue.500">
        {navName}
      </Text>
    </ChakraLink>
  )

  return (
    <PublicHeaderCont padding={4} pb={2} height="max-content" {...rest}>
      <VStack spacing={3} align="stretch" width="100%" height="100%" alignItems="center">
        <Flex width="100%" align={'center'}>
          {!drawerOpen && (
            <Flex width={isTabletOrMobile ? '30%' : ''}>
              <SideBarButton color="black" open={drawerOpen} onClick={toggleDrawer} />
            </Flex>
          )}
          <Flex width={isTabletOrMobile ? '45%' : '55%'} justify={'flex-end'}>
            <Image width={'100px'} src={images.gallopLogo} alt="Gallop Logo" />
          </Flex>
          <Flex width={isTabletOrMobile ? '45%' : '55%'} justifyContent="flex-end">
            <Flex>
              {!isAuthenticated && (
                <>
                  <Button
                    colorScheme="brand"
                    marginRight={4}
                    onClick={() => push('/register')}
                    variant="link"
                    fontSize={isTabletOrMobile ? 12 : 20}
                  >
                    Sign Up
                  </Button>
                  <Button
                    colorScheme="brand"
                    onClick={() => push('/login')}
                    variant="link"
                    fontSize={isTabletOrMobile ? 12 : 20}
                  >
                    Log In
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        {!isAuthenticated && location.pathname === '/news' && (
          <Flex width="100%">
            <SubMenu />
          </Flex>
        )}
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          {!isAuthenticated && saHorseRacingPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navSaHorseRacingItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {isAuthenticated && authSaHorseRacingPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navAuthSaHorseRacingItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {!isAuthenticated && bettingPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navBettingItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {isAuthenticated && authBettingPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navAuthBettingItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {!isAuthenticated && breedersPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navBreedersItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {isAuthenticated && authBreedersPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navAuthBreedersItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {!isAuthenticated && mediaLibraryPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navMediaItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {isAuthenticated && authMediaLibraryPath ? (
            <Flex justifyContent="flex-start" alignItems="center">
              {navAuthMediaItems.map((navtab) => (
                <MenuItem key={navtab.navName} {...navtab} />
              ))}
            </Flex>
          ) : null}
          {isAuthenticated && location.pathname === '/auth/news' && (
            <Flex alignItems="start" justifyItems="start">
              <SubMenu />
            </Flex>
          )}
          {isAuthenticated && (
            <Flex ml="auto">
              <Popover
                isLazy
                returnFocusOnClose={false}
                isOpen={notificationOpen}
                onClose={() => setNotificationOpen(!notificationOpen)}
                placement="bottom-end"
                closeOnBlur={true}
              >
                <PopoverTrigger>
                  <Avatar
                    src={images.notification}
                    alt="Notification Bell"
                    size="xs"
                    marginRight={2}
                    role="button"
                    onClick={() => {
                      setNotificationOpen(!notificationOpen)
                      getNotifications()
                    }}
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">Notifications</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody height={200} overflowY="scroll" overflowX="hidden">
                      {notifications && notifications.length > 0 ? (
                        <ChakraLink>
                          <Flex
                            direction="row-reverse"
                            marginBottom={2}
                            alignItems="center"
                            onClick={() =>
                              markAsRead({
                                variables: {
                                  input: notifications.map((notification) => notification.id)
                                }
                              })
                            }
                          >
                            <Text color="gray.400">Clear All</Text>
                          </Flex>
                        </ChakraLink>
                      ) : null}
                      {loading || markAsReadLoading ? (
                        <Center>
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                          />
                        </Center>
                      ) : (
                        notifications &&
                        notifications.length > 0 &&
                        notifications.map((notification) => {
                          return (
                            <Card bg={theme.opaqueBlue} key={notification.id} mb={3}>
                              <Flex direction="row" justifyContent="space-between">
                                <Text ml={1} fontSize={15} fontWeight={'bold'}>
                                  {notification?.title}
                                </Text>
                                <IconButton
                                  mt={1}
                                  mr={1}
                                  onClick={() =>
                                    markAsRead({
                                      variables: {
                                        input: [notification.id]
                                      }
                                    })
                                  }
                                  size="xs"
                                  bg="none"
                                  aria-label="Clear"
                                  icon={<X />}
                                />
                              </Flex>

                              <Text m={1} fontSize={12}>
                                {notification?.message}
                              </Text>
                              <Button
                                mx={2}
                                my={2}
                                width="40%"
                                height={25}
                                type="submit"
                                bg="brand.500"
                                borderRadius="24px"
                                onClick={() => {
                                  setNotificationOpen(false)
                                  history.push(`/newsview/${notification?.article?.id}`, {
                                    type: notification?.article?.title
                                  })
                                }}
                              >
                                <Text fontSize="16px" color="white">
                                  READ
                                </Text>
                              </Button>
                            </Card>
                          )
                        })
                      )}
                      {(!notifications || notifications.length === 0) &&
                      !loading &&
                      !markAsReadLoading ? (
                        <Flex align="center" justify="center" flexDirection="column">
                          <RevealFlex>
                            <Heading as="h2" my={3} fontWeight="bold">
                              Nothing to see here
                            </Heading>
                          </RevealFlex>
                        </Flex>
                      ) : null}
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
              <Link to="/auth/profile-management">
                <Avatar src={images.profile} alt="Profile" size="xs" />
              </Link>
            </Flex>
          )}
        </Flex>
      </VStack>
    </PublicHeaderCont>
  )
}

export default PublicHeader

PublicHeader.defaultProps = {
  bg: theme.opaqueBlue
}
