import { Flex, FlexProps, useMediaQuery, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { responsiveWidth } from '../../constants'

const StyledFlex = styled(Flex)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
`

type BannerProps = FlexProps & {
  title: string
  leftElement?: React.ReactElement
  rightElement?: React.ReactElement
  hasBackButton?: boolean
}

const Banner: React.FC<BannerProps> = ({
  title,
  leftElement,
  rightElement,
  hasBackButton,
  ...rest
}): JSX.Element => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)
  const history = useHistory()
  const onClickBack = () => {
    if (
      !isEmpty(history?.location.pathname) &&
      !history?.location?.pathname.includes('iframe/breedingLink') &&
      !history?.location?.pathname.includes('how-to-bet') &&
      !history?.location?.pathname.includes('stripe-reports')
    ) {
      history.replace(location.pathname)
    } else {
      history?.goBack?.()
    }
  }
  return (
    <Flex pl={5} width={'100%'} {...rest}>
      {/* go back based on history if no onClickBack is provided */}
      {hasBackButton ? (
        <StyledFlex onClick={onClickBack}>
          <Text color="blue.500" fontWeight="bold" p={isTabletOrMobile ? 0 : 2} as="u">
            {location.pathname.includes('iframe/breedingLink') ? `Breeding / ${title}` : title}
          </Text>
        </StyledFlex>
      ) : (
        <>
          {leftElement && <Flex marginRight={4}>{leftElement}</Flex>}
          <Text color="blue.500" fontWeight="bold" p={isTabletOrMobile ? 0 : 2} as="u">
            {title}
          </Text>
        </>
      )}
      {rightElement && <Flex marginLeft={4}>{rightElement}</Flex>}
    </Flex>
  )
}

Banner.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  bg: 'brand.1000',
  m: 'auto'
}

export default Banner
