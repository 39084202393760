import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

type EmptyListProps = {
  icon: JSX.Element
  text: string
}

export default function ({ icon, text }: EmptyListProps): JSX.Element {
  return (
    <Flex width="100%" justifyContent="center" alignItems="center" bg="gray.100">
      <Box textAlign="center" py={8}>
        <Flex justifyContent="center">{icon}</Flex>
        <Text pt={4} fontWeight="bold" color="gray.500" fontSize="1.25rem">
          {text}
        </Text>
      </Box>
    </Flex>
  )
}
