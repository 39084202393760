import { ApolloError } from '@apollo/client'
import { Center, Flex, FlexProps, Image, useToast } from '@chakra-ui/react'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../constants'
import { useAppContext } from 'context/AppProvider'
import {
  SavedMediaInput,
  UsersPermissionsUser,
  useToggleBookmarkArticleMutation,
  useToggleBookmarkVideoMutation
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { images } from 'theme'
import { useAuthContext } from 'context/AuthProvider'
import { Item } from 'utils'

type BookShareProps = FlexProps & {
  shareUrl: string
  isMedia?: boolean
  itemId: string
  video?: Item
}

const BookMarkAndShare = ({
  shareUrl,
  isMedia,
  itemId,
  video,
  ...props
}: BookShareProps): JSX.Element => {
  const toast = useToast()
  const { handleShare } = useAppContext()
  const { user, setUser, isAuthenticated } = useAuthContext()
  const [isBookmarked, setIsBookmarked] = useState(false)

  const [bookmarkMedia] = useToggleBookmarkVideoMutation({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: ({ toggleBookmarkVideo }) => {
      setIsBookmarked(!isBookmarked)
      if (setUser) {
        setUser({ ...user, profile: { ...toggleBookmarkVideo } } as UsersPermissionsUser)
        toast({
          description: `Successfully ${isBookmarked ? 'removed' : 'added'} video bookmark!`,
          ...SUCCESS_TOAST
        })
      }
    }
  })
  const [bookMarkArticle] = useToggleBookmarkArticleMutation({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: ({ toggleBookmarkArticle }) => {
      toast({
        description: `Successfully ${isBookmarked ? 'removed' : 'added'} bookmark!`,
        ...SUCCESS_TOAST
      })
      setIsBookmarked(!isBookmarked)
      if (setUser) {
        setUser({ ...user, profile: { ...toggleBookmarkArticle } } as UsersPermissionsUser)
      }
    }
  })

  const handleBookmark = async () => {
    if (isMedia) {
      const { kind, etag, id, snippet } = { ...video }
      const media: SavedMediaInput = {
        videoId: id?.videoId as string,
        videoKind: id?.kind,
        kind,
        etag,
        snippet
      }
      await bookmarkMedia({
        variables: {
          input: {
            isBookmark: !isBookmarked,
            video: { ...media }
          }
        }
      })
    } else {
      await bookMarkArticle({
        variables: {
          input: {
            articleId: itemId,
            isBookmark: !isBookmarked
          }
        }
      })
    }
  }

  useEffect(() => {
    if (isMedia) {
      Array.isArray(user?.profile?.bookmarkedMedia) &&
        setIsBookmarked(!!user?.profile?.bookmarkedMedia.find((video) => video?.videoId === itemId))
    } else {
      Array.isArray(user?.profile?.bookmarkedArticles) &&
        setIsBookmarked(
          !!user?.profile?.bookmarkedArticles.find((article) => article?.id === itemId)
        )
    }
  }, [user?.profile?.bookmarkedArticles, user?.profile?.bookmarkedMedia, itemId, isMedia])

  return (
    <>
      <Flex
        width="68px"
        mt={4}
        ml={['75%', '90%']}
        display="inline-flex"
        flexDirection="column"
        position="absolute"
        zIndex={2}
        right={5}
        top={5}
        {...props}
      >
        {isAuthenticated && user && (
          <Center
            width="63px"
            height="63px"
            ml="4%"
            borderRadius="30px"
            borderColor="brand.300"
            bg="brand.300"
            className="show-on-hover"
            _hover={{
              bgColor: 'brand.500'
            }}
            display="none"
            cursor="pointer"
            role="button"
            data-testid="bookmark-button"
            onClick={handleBookmark}
          >
            <Image src={images.bookmark} alt="bookmark" />
          </Center>
        )}
        {isAuthenticated && user && (
          <Center
            width="63px"
            height="63px"
            ml="4%"
            mt={3}
            borderRadius="30px"
            borderColor="brand.300"
            bg="brand.300"
            className="show-on-hover"
            _hover={{
              bgColor: 'brand.500'
            }}
            display="none"
            cursor="pointer"
            role="banner"
            data-testid="share-button"
            onClick={() => handleShare(shareUrl)}
          >
            <Image src={images.shareSocial} alt="share" />
          </Center>
        )}
      </Flex>
    </>
  )
}

export default BookMarkAndShare
