import * as React from 'react'

type BettingLogo = React.SVGProps<SVGSVGElement> & {
  iconColor?: React.SVGAttributes<SVGPathElement>['fill']
}

const BettingLogo = ({ iconColor = '#D1E7FF', ...props }: BettingLogo): JSX.Element => {
  return (
    <svg
      width="196"
      height="196"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <circle cx="98" cy="98" r="90" fill={iconColor} />
      </g>
      <path
        d="M67.9375 109.563L128.063 109.562C129.34 109.562 130.375 108.527 130.375 107.25V74.875C130.375 73.5978 129.34 72.5625 128.063 72.5625H67.9375C66.6604 72.5625 65.625 73.5978 65.625 74.875V107.25C65.625 108.527 66.6604 109.563 67.9375 109.563Z"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M70.25 116.5H125.75"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.875 123.438H121.125"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98 102.627C104.386 102.627 109.562 97.4502 109.562 91.0644C109.562 84.6787 104.386 79.502 98 79.502C91.6142 79.502 86.4375 84.6787 86.4375 91.0644C86.4375 97.4502 91.6142 102.627 98 102.627Z"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.375 84.125C127.308 84.125 124.367 82.9068 122.199 80.7384C120.03 78.57 118.812 75.6291 118.812 72.5625"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.625 84.125C67.1434 84.125 68.6469 83.8259 70.0498 83.2449C71.4526 82.6638 72.7272 81.8121 73.8009 80.7384C74.8746 79.6647 75.7263 78.3901 76.3074 76.9873C76.8884 75.5844 77.1875 74.0809 77.1875 72.5625"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.375 98.002C127.308 98.002 124.367 99.2201 122.199 101.389C120.03 103.557 118.812 106.498 118.812 109.564"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.625 98.002C67.1434 98.002 68.6469 98.301 70.0498 98.8821C71.4526 99.4632 72.7272 100.315 73.8009 101.389C74.8746 102.462 75.7263 103.737 76.3074 105.14C76.8884 106.542 77.1875 108.046 77.1875 109.564"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="196"
          height="196"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default BettingLogo
