import { Box, Flex, Stack, Text } from '@chakra-ui/layout'
import * as React from 'react'
import { dateFormat, Item } from 'utils'
import { useHistory } from 'react-router-dom'
import { BookMarkAndShare } from 'components'

type ItemViewProps = {
  itemId?: string
  url: string
  title: string
  description: string
  publishedAt: string
  writer?: string
  video: Item
}

const ItemView = ({
  itemId,
  url,
  title,
  description,
  publishedAt,
  writer,
  video
}: ItemViewProps): JSX.Element => {
  const history = useHistory()

  const handleOpenVideo = (id: string | undefined) => {
    history.push(`/video/${id}`)
  }

  return (
    <Box
      width="100%"
      borderRadius={4}
      marginBottom={5}
      bgColor="white"
      cursor={'pointer'}
      position="relative"
      __css={{
        '&:hover .show-on-hover': {
          display: 'flex'
        }
      }}
    >
      <BookMarkAndShare
        position="absolute"
        top="1rem"
        right="1rem"
        itemId={itemId as string}
        shareUrl={`https://www.youtube.com/${itemId}`}
        video={video}
        isMedia
      />
      <Flex
        borderRadius={4}
        width="100%"
        minHeight={['200px', null, null, '250px', '300px']}
        maxHeight={['200px', null, null, '250px', '300px']}
        bg="gray.50"
        backgroundImage={`url(${url})`}
        bgSize="cover"
        bgRepeat="no-repeat"
        height="852px"
        bgPosition="center"
        role="button"
        onClick={() => handleOpenVideo(itemId)}
      ></Flex>
      <Stack
        p={4}
        maxHeight="175px"
        borderRadius={4}
        role="button"
        onClick={() => handleOpenVideo(itemId)}
      >
        <Flex height="50px" overflow="hidden">
          <Text fontWeight="bold" fontSize={'18px'}>
            {title}
          </Text>
        </Flex>
        <Flex mt={2} height="20px">
          <Text fontSize="12px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {description}
          </Text>
        </Flex>
        <Flex mt={2}>
          <Text fontSize="14px" lineHeight="24px">
            {publishedAt && dateFormat(publishedAt)}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          {writer && <Text fontSize="14px">{writer}</Text>}
          <Text fontSize="14px">Gold Circle</Text>
        </Flex>
      </Stack>
    </Box>
  )
}

export default ItemView
