import { ApolloError } from '@apollo/client'
import { Flex, Image, Text, useToast } from '@chakra-ui/react'
import { useAuthContext } from 'context/AuthProvider'
import { UsersPermissionsUser, useUpdateUserProfileMutation } from 'generated/graphql'
import * as React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { X } from 'react-feather'
import { images, theme } from 'theme'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../constants'

const { colors } = theme

type OrderSelectorProps = {
  userOrder: string[]
  updateOrder: (order: string[]) => void
}

type OrderItemType = { id: string; content: string }

// Function to map the news order
const getItems = (order: string[]): OrderItemType[] =>
  order.map((orderItem, index) => ({
    id: `${orderItem}-${index}`,
    content: orderItem
  }))

// Function to help us with reordering the result
const reorder = (list: OrderItemType[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

// Function to set draggable row styles
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  paddingLeft: grid * 2,
  margin: `0 0 ${grid}px 0`,
  color: 'white',
  background: isDragging ? `${colors.blue[100]}` : `white`,
  ...draggableStyle
})

// Function to set container styles
const getListStyle = () => ({
  background: 'white',
  padding: grid,
  border: `2px solid ${colors.blue[500]}`
})

const OrderSelector = ({ userOrder, updateOrder }: OrderSelectorProps): JSX.Element => {
  const { user, setUser } = useAuthContext()
  const [items, setItems] = React.useState<OrderItemType[]>([])
  const [showOrder, setShowOrder] = React.useState(false)
  const toast = useToast()

  React.useEffect(() => {
    if (userOrder.length > 0) {
      const mapOrderItems = getItems(userOrder)
      setItems(mapOrderItems)
    }
  }, [userOrder])

  const [updateUserProfile] = useUpdateUserProfileMutation({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST }),
    onCompleted: ({ updateUserProfile }) => {
      if (updateUserProfile?.profile && setUser) {
        setUser({ ...user, profile: { ...updateUserProfile.profile } } as UsersPermissionsUser)
      }
      updateOrder(items.map((item) => item.content))
      toast({ description: 'Successfully Updated your articles order!', ...SUCCESS_TOAST })
    }
  })

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const reorderedItems = reorder(items, result.source.index, result.destination.index)

    setItems(reorderedItems)
    await updateUserProfile({
      variables: {
        input: {
          newsCategoriesOrder: reorderedItems.map((reorderedItem) => reorderedItem.content)
        }
      }
    })
  }

  return (
    <>
      {showOrder ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Flex
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle()}
                flexDir="column"
                position="absolute"
                width={['90%', '350px']}
                mx={['auto', 2]}
                mt={2}
                zIndex={10}
              >
                <Flex height="50px" justify="space-between" m={2} px={2}>
                  <Text fontSize="16px">Drag title tiles to Re-order your articles.</Text>
                  <X onClick={() => setShowOrder((prevSate) => !prevSate)} width="30px" />
                </Flex>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Flex
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <Text
                          fontSize="16px"
                          bg="blue.500"
                          mr={4}
                          width="50px"
                          height="50px"
                          textAlign="center"
                          py={3}
                        >
                          {index + 1}
                        </Text>
                        <Text
                          fontSize="16px"
                          bg="brand.100"
                          width="250px"
                          height="50px"
                          textAlign="center"
                          py={3}
                        >
                          {item.content}
                        </Text>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Flex
          width="max-content"
          border={`2px solid ${colors.blue[500]}`}
          borderRadius="60px"
          height="50px"
          align="center"
          justify="space-between"
          m={2}
          px={4}
          role="button"
          onClick={() => setShowOrder((prevSate) => !prevSate)}
        >
          <Image alignSelf="center" justify="center" src={images.reOrder} width="30px" mr={2} />
          <Text fontSize="18px" color="blue.500" fontWeight={600}>
            Re-order your news
          </Text>
        </Flex>
      )}
    </>
  )
}

export default OrderSelector
