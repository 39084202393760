import { Flex, FlexProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Variants } from 'framer-motion'
import * as React from 'react'
import { MotionFlex, Version } from '..'

const PanelWrapper = styled(MotionFlex)`
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  display: flex;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    position: relative;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const variants: Variants = {
  hidden: {
    x: '-50%'
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 18
    }
  }
}

const SideSlider: React.FC<FlexProps> = ({ children, justify }) => {
  return (
    <PanelWrapper
      px={4}
      pt={2}
      m={0}
      bg="white"
      initial="hidden"
      animate="visible"
      rounded={['md', 0]}
      variants={variants}
      width="50%"
      height="100%"
    >
      <Flex flexDir="column" width="100%" justify={justify}>
        {children}
        <Version p={2} />
      </Flex>
    </PanelWrapper>
  )
}

export default SideSlider

SideSlider.defaultProps = {
  justify: 'space-between'
}
