// v4 causes dynamic image imports to be objects: https://github.com/facebook/create-react-app/issues/9992
export default {
  bg: require('../assets/images/core-bg.jpg')?.default,
  obs: require('../assets/images/onboarding_empty.svg')?.default,
  onb: require('../assets/images/onboarding_empty.png')?.default,
  pv: require('../assets/images/password-reset.png')?.default,
  ev: require('../assets/images/email-verification.jpg')?.default,
  mev: require('../assets/images/mobile-email-verification.png')?.default,
  spl: require('../assets/images/splash-screen.png')?.default,
  mspl: require('../assets/images/mobile-splash-screen.png')?.default,
  bstck: require('../assets/images/bloodstock.png')?.default,
  cpstck: require('../assets/images/cape-throughbreed.png')?.default,
  capeRacing: require('../assets/images/cape-racing.png')?.default,
  tbrdstck: require('../assets/images/thoroughbred.png')?.default,
  sovtechLogo: require('../assets/images/sovtech-small.png')?.default,
  404: require('../assets/images/404.svg')?.default,
  noData: require('../assets/images/no-data.png')?.default,
  newsIcon: require('../assets/images/news-icon.svg')?.default,
  cashIcon: require('../assets/images/cash-icon.svg')?.default,
  flagIcon: require('../assets/images/flag-icon.svg')?.default,
  lockIcon: require('../assets/images/lock-icon.svg')?.default,
  playIcon: require('../assets/images/play-icon.svg')?.default,
  statsIcon: require('../assets/images/stats-icon.svg')?.default,
  reOrder: require('../assets/images/re-order.svg')?.default,
  gallopLogo: require('../assets/svg/Gallop.svg')?.default,
  gallopLogin: require('../assets/images/login-gallop.jpg')?.default,
  jockeyHelmet: require('../assets/images/jockey-helmet.svg')?.default,
  owner: require('../assets/images/owner-bag.svg')?.default,
  punter: require('../assets/images/punter-coin.svg')?.default,
  generalUser: require('../assets/images/general-user.svg')?.default,
  trainer: require('../assets/images/horse-jump.svg')?.default,
  userType: require('../assets/images/user-type.png')?.default,
  backArrow: require('../assets/images/back-arrow.svg')?.default,
  bookmark: require('../assets/images/bookmark.svg')?.default,
  shareSocial: require('../assets/images/share-social.svg')?.default,
  folder: require('../assets/images/folder.svg')?.default,
  forward: require('../assets/images/forward.svg')?.default,
  outline: require('../assets/images/outline.svg')?.default,
  closeModal: require('../assets/images/modal-close.svg')?.default,
  inviteModal: require('../assets/images/invite-email-modal.png')?.default,
  notification: require('../assets/images/notification.svg')?.default,
  profile: require('../assets/images/profile.svg')?.default,
  how2Bet: require('../assets/images/how-to-bet.png')?.default,
  tabGold: require('../assets/images/tab-gold.png')?.default,
  trackNball: require('../assets/images/track-n-ball.png')?.default,
  readRaceCard: require('../assets/images/read-race-card.svg')?.default,
  raceOfficial: require('../assets/images/racing-official.png')?.default,
  nhra: require('../assets/images/nhra.png')?.default,
  nris: require('../assets/images/nris.png')?.default,
  raceDownload: require('../assets/images/race-download.png')?.default,
  raceTrainer: require('../assets/images/racing-trainer.png')?.default,
  computForm: require('../assets/images/comput-form.png')?.default,
  facebook: require('../assets/images/facebookLogo.png')?.default,
  twitter: require('../assets/images/twitterLogo.png')?.default,
  instagram: require('../assets/images/instagramLogo.png')?.default,
  youtube: require('../assets/images/youtubeLogo.png')?.default,
  greyVille: require('../assets/images/grey-ville.jpeg')?.default,
  scottsVille: require('../assets/images/scotts-ville.jpeg')?.default,
  bettingNews: require('../assets/images/betting-news.svg')?.default,
  breedingNews: require('../assets/images/breeding-news.svg')?.default,
  regulatoryNews: require('../assets/images/regulatory-news.svg')?.default,
  internationalNews: require('../assets/images/international-news.svg')?.default,
  eventNews: require('../assets/images/event-news.svg')?.default,
  saHorseIcon: require('../assets/images/horseshoe.svg')?.default,
  bredIcon: require('../assets/images/horse.svg')?.default,
  fixtureIcon: require('../assets/images/calendar-blank.svg')?.default,
  defaultNewsImage: require('../assets/images/default_news_image.png')?.default,
  galloptvIcon: require('../assets/images/smart-tv.svg')?.default,
  faqIcon: require('../assets/images/faq.svg')?.default,
  stripeIcon: require('../assets/images/stripe-stats.svg')?.default,
  eventsIcon: require('../assets/images/marquee.png').default,
  termsIcon: require('../assets/images/terms-and-conditions.png').default
}
