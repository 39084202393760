import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    )

    try {
      const component = await componentImport()
      window.localStorage.setItem('page-has-been-force-refreshed', 'false')

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
        return window.location.reload() // refresh the page
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error // Default error behaviour as already tried refresh
    }
  })

const Users = lazyWithRetry(() => import('../containers/UserManagement'))
const Profile = lazyWithRetry(() => import('../containers/Profile'))
const RegisterAndLogin = lazyWithRetry(() => import('../containers/RegisterAndLogin'))
const ForgotPassword = lazyWithRetry(() => import('../containers/ForgotPassword'))
const ResetPassword = lazyWithRetry(() => import('../containers/ResetPassword'))
const ConfirmReset = lazyWithRetry(() => import('../containers/ConfirmReset'))
const Onboarding = lazyWithRetry(() => import('../containers/Onboarding'))
const Terms = lazyWithRetry(() => import('../containers/Terms'))
const UserType = lazyWithRetry(() => import('../containers/UserType'))
const News = lazyWithRetry(() => import('../containers/News'))
const Fixture = lazyWithRetry(() => import('../containers/Fixture'))
const Breeders = lazyWithRetry(() => import('../containers/Breeders'))
const IframeBody = lazyWithRetry(() => import('../containers/IframeBody'))
const SplashScreen = lazyWithRetry(() => import('../containers/SplashScreen'))
const Events = lazyWithRetry(() => import('../containers/Events'))
const NewsView = lazyWithRetry(() => import('../containers/NewsView'))
const Betting = lazyWithRetry(() => import('../containers/Betting'))
const MediaLibrary = lazyWithRetry(() => import('../containers/MediaLibrary'))
const Statistics = lazyWithRetry(() => import('../containers/Statistics'))
const SAHorseRacing = lazyWithRetry(() => import('../containers/SAHorseRacing'))
const MobileSlides = lazyWithRetry(() => import('../containers/MobileSlides'))
const ArticleView = lazyWithRetry(() => import('../containers/ArticleView'))
const ProfileManagement = lazyWithRetry(() => import('../containers/ProfileManagement'))
const SavedItem = lazyWithRetry(() => import('../containers/SavedItem'))
const BettingGuide = lazyWithRetry(() => import('../containers/BettingGuide'))
const SimDraw = lazyWithRetry(() => import('../containers/SimDraw'))
const Programme = lazyWithRetry(() => import('../containers/Programme'))
const MediaPhoto = lazyWithRetry(() => import('../containers/MediaPhoto'))
const VideoView = lazyWithRetry(() => import('../containers/VideoView'))
const EventView = lazyWithRetry(() => import('../containers/EventView'))
const AuthRedirect = lazyWithRetry(() => import('../containers/AuthRedirect'))
const Nhra = lazyWithRetry(() => import('../containers/Nhra'))
const GoldCircle = lazyWithRetry(() => import('../containers/GoldCircle'))
const AfricanBettingClan = lazyWithRetry(() => import('../containers/AfricanBettingClan'))
const GallopTv = lazyWithRetry(() => import('../containers/GallopTv'))
const FAQs = lazyWithRetry(() => import('../containers/FAQs'))
const StripeReports = lazyWithRetry(() => import('../containers/StripeReports'))
const StripeReport = lazyWithRetry(() => import('../containers/StripeReports/single'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/auth/user-management',
    breadcrumb: 'User Management',
    component: Users,
    title: 'User Management'
  },
  {
    exact: false,
    path: '/auth/onboarding',
    breadcrumb: 'Onboarding',
    component: Onboarding,
    title: 'Onboarding'
  },
  {
    exact: false,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  },
  {
    exact: true,
    path: '/auth/news',
    breadcrumb: 'News',
    component: News,
    title: 'News'
  },
  {
    exact: false,
    path: '/auth/betting',
    breadcrumb: 'Betting',
    component: Betting,
    title: 'Betting'
  },
  {
    exact: false,
    path: '/auth/profile-management',
    breadcrumb: 'Profile Management',
    component: ProfileManagement,
    title: 'Profile Management'
  },
  {
    exact: false,
    path: '/auth/fixtures/:iframeName',
    breadcrumb: 'Fixture',
    component: Fixture,
    title: 'Fixture'
  },
  {
    exact: false,
    path: '/auth/sa-horse-racing/:iframeName',
    breadcrumb: 'SA Horse Racing',
    component: SAHorseRacing,
    title: 'SA Horse Racing'
  },
  {
    exact: false,
    path: '/auth/programme/:iframeName',
    breadcrumb: 'SA Horse Racing',
    component: Programme,
    title: 'SA Horse Racing'
  },
  {
    exact: false,
    path: '/auth/simdraw/:iframeName',
    breadcrumb: 'Simdraw',
    component: SimDraw,
    title: 'Simdraw'
  },
  {
    exact: false,
    path: '/auth/breeders',
    breadcrumb: 'Breeders',
    component: Breeders,
    title: 'Breeders'
  },
  {
    exact: false,
    path: '/auth/events',
    breadcrumb: 'Events',
    component: Events,
    title: 'Events'
  },
  {
    exact: false,
    path: '/auth/terms',
    breadcrumb: 'Terms',
    component: Terms,
    title: 'Terms'
  },
  {
    exact: true,
    path: '/auth/user-type/:componentSource',
    breadcrumb: 'User Type',
    component: UserType,
    title: 'UserType'
  },
  {
    exact: false,
    path: '/auth/media-library',
    breadcrumb: 'Media Library',
    component: MediaLibrary,
    title: 'Media Library'
  },
  {
    exact: false,
    path: '/auth/media-photo',
    breadcrumb: 'Media Photo',
    component: MediaPhoto,
    title: 'Media Photo'
  },
  {
    exact: true,
    path: '/auth/faqs',
    breadcrumb: 'FAQs',
    component: FAQs,
    title: 'FAQs'
  },
  {
    exact: false,
    path: '/auth/how-to-bet',
    breadcrumb: 'How does betting work',
    component: BettingGuide,
    title: 'How does betting work'
  },
  {
    exact: false,
    path: '/auth/galloptv/:iframeName',
    breadcrumb: 'Gallop Tv',
    component: GallopTv,
    title: 'Gallop Tv'
  },
  {
    exact: false,
    path: '/auth/media-library',
    breadcrumb: 'Media Library',
    component: MediaLibrary,
    title: 'Media Library'
  },
  {
    exact: false,
    path: '/auth/video/:id',
    breadcrumb: 'Video',
    component: VideoView,
    title: 'Video View'
  },
  {
    exact: true,
    path: '/auth/stripe-reports',
    breadcrumb: 'Stripe Reports',
    component: StripeReports,
    title: 'Stripe Reports'
  },
  {
    exact: true,
    path: '/auth/stripe-reports/:id',
    breadcrumb: 'Stripe Report',
    component: StripeReport,
    title: 'Stripe Report'
  },
  {
    exact: false,
    path: '/auth/iframe/:iframeName',
    breadcrumb: 'iframe',
    component: IframeBody,
    title: 'Iframe body'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/login/:confirmed?',
    component: RegisterAndLogin
  },
  {
    exact: true,
    title: 'AuthRedirect',
    path: '/callback',
    component: AuthRedirect
  },
  {
    exact: true,
    title: 'Register',
    path: '/register/:confirmed?',
    component: RegisterAndLogin
  },
  {
    exact: true,
    title: 'Slides',
    path: '/mobile-slides',
    component: MobileSlides
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Confirm Reset',
    path: '/confirm-reset',
    component: ConfirmReset
  },
  {
    exact: true,
    path: '/news',
    breadcrumb: 'News',
    component: News,
    title: 'News'
  },
  {
    exact: false,
    path: '/fixtures/:iframeName',
    breadcrumb: 'Fixture',
    component: Fixture,
    title: 'Fixture'
  },
  {
    exact: false,
    path: '/sa-horse-racing/:iframeName',
    breadcrumb: 'SA Horse Racing',
    component: SAHorseRacing,
    title: 'SA Horse Racing'
  },
  {
    exact: false,
    path: '/programme/:iframeName',
    breadcrumb: 'SA Horse Racing',
    component: Programme,
    title: 'SA Horse Racing'
  },
  {
    exact: false,
    path: '/simdraw/:iframeName',
    breadcrumb: 'Simdraw',
    component: SimDraw,
    title: 'Simdraw'
  },
  {
    exact: false,
    path: '/nhra/:iframeName',
    breadcrumb: 'Nhra',
    component: Nhra,
    title: 'Nhra'
  },
  {
    exact: false,
    path: '/terms',
    breadcrumb: 'Terms',
    component: Terms,
    title: 'Terms'
  },
  {
    exact: false,
    path: '/goldcircle/:iframeName',
    breadcrumb: 'GoldCircle',
    component: GoldCircle,
    title: 'GoldCircle'
  },
  {
    exact: false,
    path: '/africanbettingclan/:iframeName',
    breadcrumb: 'AfricanBettingClan',
    component: AfricanBettingClan,
    title: 'AfricanBettingClan'
  },
  {
    exact: false,
    path: '/breeders',
    breadcrumb: 'Breeders',
    component: Breeders,
    title: 'Breeders'
  },
  {
    exact: false,
    path: '/iframe/:iframeName',
    breadcrumb: 'iframe',
    component: IframeBody,
    title: 'Iframe body'
  },
  {
    exact: true,
    path: '/',
    breadcrumb: 'Splash screen',
    component: SplashScreen,
    title: 'Splash Screen'
  },
  {
    exact: false,
    path: '/events',
    breadcrumb: 'Events',
    component: Events,
    title: 'Events'
  },
  {
    path: '/newsview/:id',
    breadcrumb: 'News',
    component: NewsView,
    title: 'News View'
  },
  {
    path: '/article/:id',
    breadcrumb: 'Article',
    component: ArticleView,
    title: 'Article View'
  },
  {
    path: '/video/:id',
    breadcrumb: 'Video',
    component: VideoView,
    title: 'Video View'
  },
  {
    path: '/event/:id',
    breadcrumb: 'Event View',
    component: EventView,
    title: 'Event View'
  },
  {
    exact: false,
    path: '/betting',
    breadcrumb: 'Betting',
    component: Betting,
    title: 'Betting'
  },
  {
    exact: false,
    path: '/media-library',
    breadcrumb: 'Media Library',
    component: MediaLibrary,
    title: 'Media Library'
  },
  {
    exact: false,
    path: '/media-photo',
    breadcrumb: 'Media Photo',
    component: MediaPhoto,
    title: 'Media Photo'
  },
  {
    exact: false,
    path: '/statistics/:iframeName',
    breadcrumb: 'Statistics',
    component: Statistics,
    title: 'Statistics'
  },
  {
    exact: false,
    path: '/galloptv/:iframeName',
    breadcrumb: 'Gallop Tv',
    component: GallopTv,
    title: 'Gallop Tv'
  },
  {
    exact: false,
    path: '/auth/profile-management',
    breadcrumb: 'Profile Management',
    component: ProfileManagement,
    title: 'Profile Management'
  },
  {
    exact: false,
    path: '/saved/:documentType',
    breadcrumb: 'Saved Item',
    component: SavedItem,
    title: 'Saved Item'
  },
  {
    exact: true,
    path: '/auth/user-type/:componentSource',
    breadcrumb: 'User Type',
    component: UserType,
    title: 'UserType'
  },
  {
    exact: false,
    path: '/how-to-bet',
    breadcrumb: 'How does betting work',
    component: BettingGuide,
    title: 'How does betting work'
  },
  {
    exact: true,
    path: '/faqs',
    breadcrumb: 'FAQs',
    component: FAQs,
    title: 'FAQs'
  },
  {
    exact: true,
    path: '/stripe-reports',
    breadcrumb: 'Stripes Reports',
    component: StripeReports,
    title: 'Stripes Reports'
  },
  {
    exact: true,
    path: '/stripe-reports/:id',
    breadcrumb: 'Stripe Report',
    component: StripeReport,
    title: 'Stripe Report'
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
