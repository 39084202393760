import { ApolloProvider } from '@apollo/react-hooks'
import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import React from 'react'
import 'react-dates/initialize'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { RecoilRoot } from 'recoil'
import client from './apollo'
import App from './App'
import { APP_NAME, BUGSNAG_API_KEY } from './constants'
import { AppProvider, AuthProvider } from './context'
import * as serviceWorker from './serviceWorker'
import { theme } from './theme'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
})

// RECOMMENDED: to make use of the provided type definitions, update to the new api
// Note the use of the ! operator. The getPlugin('react') call will only return something if the react
// plugin was provided to Bugsnag.start({ plugins: […] }).

// eslint-disable-next-line
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <RecoilRoot>
            <AuthProvider>
              <Helmet titleTemplate={`${APP_NAME} | %s`} />
              <AppProvider>
                <CSSReset />
                <Global
                  styles={css`
                    * {
                      font-family: ${theme.fonts.body};
                    }
                  `}
                />
                <App />
              </AppProvider>
            </AuthProvider>
          </RecoilRoot>
        </ChakraProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
