import * as React from 'react'

type StatisticLogo = React.SVGProps<SVGSVGElement> & {
  iconColor?: React.SVGAttributes<SVGPathElement>['fill']
}

const StatisticLogo = ({ iconColor = '#D1E7FF', ...props }: StatisticLogo): JSX.Element => {
  return (
    <svg
      width="196"
      height="196"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <circle cx="98" cy="98" r="90" fill={iconColor} />
      </g>
      <path
        d="M76.031 130.189H71.406C70.8839 130.189 70.3831 129.982 70.0139 129.613C69.6447 129.244 69.4373 128.743 69.4373 128.221V107.408C69.4373 106.886 69.6447 106.385 70.0139 106.016C70.3831 105.647 70.8839 105.439 71.406 105.439H76.031C76.5531 105.439 77.0539 105.647 77.4231 106.016C77.7923 106.385 77.9998 106.886 77.9998 107.408V128.221C77.9998 128.743 77.7923 129.244 77.4231 129.613C77.0539 129.982 76.5531 130.189 76.031 130.189Z"
        stroke="#316FB4"
        strokeWidth="3"
      />
      <path
        d="M108.406 130.186H103.781C103.259 130.186 102.758 129.978 102.389 129.609C102.02 129.24 101.812 128.739 101.812 128.217V93.5293C101.812 93.0072 102.02 92.5064 102.389 92.1372C102.758 91.768 103.259 91.5605 103.781 91.5605H108.406C108.928 91.5605 109.429 91.768 109.798 92.1372C110.168 92.5064 110.375 93.0072 110.375 93.5293V128.217C110.375 128.739 110.168 129.24 109.798 129.609C109.429 129.978 108.928 130.186 108.406 130.186Z"
        stroke="#316FB4"
        strokeWidth="3"
      />
      <path
        d="M124.594 130.187H119.969C119.446 130.187 118.946 129.98 118.576 129.611C118.207 129.242 118 128.741 118 128.219V77.3437C118 76.8216 118.207 76.3208 118.576 75.9516C118.946 75.5824 119.446 75.375 119.969 75.375H124.594C125.116 75.375 125.616 75.5824 125.986 75.9516C126.355 76.3208 126.562 76.8216 126.562 77.3437V128.219C126.562 128.741 126.355 129.242 125.986 129.611C125.616 129.98 125.116 130.187 124.594 130.187Z"
        stroke="#316FB4"
        strokeWidth="3"
      />
      <path
        d="M94.6715 130.67C94.021 131.32 93.1387 131.686 92.2187 131.686H87.5937C86.6738 131.686 85.7915 131.32 85.141 130.67V63.3265C85.7915 62.676 86.6738 62.3105 87.5937 62.3105H92.2187C93.1387 62.3105 94.021 62.676 94.6715 63.3265C95.322 63.977 95.6875 64.8593 95.6875 65.7793V128.217C95.6875 129.137 95.322 130.019 94.6715 130.67ZM94.6715 130.67L93.6109 129.609C93.2417 129.978 92.7409 130.186 92.2187 130.186H87.5937C87.0716 130.186 86.5708 129.978 86.2016 129.609L85.1433 130.667L86.2016 129.609C85.8324 129.24 85.625 128.739 85.625 128.217V65.7793C85.625 65.2572 85.8324 64.7564 86.2016 64.3872C86.5708 64.018 87.0716 63.8105 87.5937 63.8105H92.2187C92.7409 63.8105 93.2417 64.018 93.6109 64.3872C93.9801 64.7564 94.1875 65.2572 94.1875 65.7793V128.217C94.1875 128.739 93.9801 129.24 93.6109 129.609L94.6715 130.67Z"
        stroke="#316FB4"
        strokeWidth="3"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="196"
          height="196"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default StatisticLogo
