import { Circle, Flex } from '@chakra-ui/react'
import React from 'react'
import { Slide } from './SliderContent'

type DotProps = {
  activeIndex: any
  onClick: Function
  slides: Slide[]
}

const Dots: React.FC<DotProps> = ({ activeIndex, onClick, slides }: DotProps) => {
  return (
    <Flex
      width="100%"
      height="100%"
      position="absolute"
      justifyContent="center"
      zIndex={200}
      top={['85%', '90%']}
    >
      {slides.map((_, index: React.Key | null | undefined) => {
        return (
          <Circle
            width={8}
            height={8}
            backgroundColor={activeIndex === index ? 'white' : 'rgba(0, 0, 0, 0.3)'}
            cursor="pointer"
            key={index}
            onClick={() => onClick(index)}
            marginRight={index === slides.length - 1 ? 0 : 4}
          />
        )
      })}
    </Flex>
  )
}

export default Dots
