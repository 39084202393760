import * as React from 'react'

type OnlineLogo = React.SVGProps<SVGSVGElement> & {
  iconColor?: React.SVGAttributes<SVGPathElement>['fill']
}

const OnlineLogo = ({ iconColor = '#D1E7FF', ...props }: OnlineLogo): JSX.Element => {
  return (
    <svg
      width="196"
      height="196"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <circle cx="98" cy="98" r="90" fill={iconColor} />
      </g>
      <path
        d="M72.5625 128.062V70.8484C72.5632 70.6461 72.617 70.4475 72.7184 70.2725C72.8199 70.0976 72.9654 69.9522 73.1406 69.8511C74.2694 69.1891 77.3205 67.9375 84.125 67.9375C93.375 67.9375 105.082 74.875 111.875 74.875C115.71 74.865 119.505 74.0902 123.037 72.5957C123.081 72.5774 123.129 72.5702 123.176 72.5748C123.224 72.5794 123.269 72.5957 123.309 72.6221C123.349 72.6486 123.381 72.6845 123.403 72.7265C123.426 72.7685 123.438 72.8155 123.438 72.8631V104.567C123.437 104.679 123.404 104.789 123.343 104.883C123.282 104.976 123.195 105.05 123.092 105.095C121.901 105.617 117.704 107.25 111.875 107.25C104.938 107.25 93.375 102.625 84.125 102.625C74.875 102.625 72.5625 104.937 72.5625 104.937"
        stroke="#316FB4"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="196"
          height="196"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default OnlineLogo
