import { Flex, Image, useMediaQuery } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import * as React from 'react'
import { ChevronDown } from 'react-feather'
import { responsiveWidth } from '../../../constants'
import { useAppContext } from '../../../context/AppProvider'
import { Text } from '../../../typography'
import { MenuItem, Tooltip } from './styles'

type SideBarItemProps = {
  title: string | React.ReactElement
  to: string
  color: string
  hoverColor: string
  accentColor: string
  icon: string | React.ReactElement
  subMenu?: Array<{ to: string; title: string }>
  tooltipColor?: string
  tooltipBg?: string
  closeOnNavigate?: boolean
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  accentColor,
  color,
  hoverColor,
  icon,
  title,
  to,
  subMenu,
  tooltipColor,
  tooltipBg,
  closeOnNavigate
}) => {
  const { drawerOpen, toggleDrawer } = useAppContext()
  const [showSubMenu, setShowSubMenu] = React.useState<boolean>(false)

  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  const variants = {
    open: {
      x: 0,
      transition: {
        x: { stiffness: 200, velocity: -100 }
      }
    },
    closed: {
      x: isTabletOrMobile ? -50 : 0,
      transition: {
        x: { stiffness: 200 }
      }
    }
  }
  return (
    <>
      <MenuItem
        replace={true}
        to={to}
        color={color}
        bg={accentColor}
        variants={variants}
        hovercolor={hoverColor}
        onClick={() => {
          if (drawerOpen) {
            toggleDrawer()
          }
        }}
        accentcolor={accentColor}
        activeClassName="active-nav-link"
      >
        <Flex
          ml={4}
          mr="5px"
          height="45px"
          alignItems="center"
          justifyContent="center"
          className="sidebar-nav-item-wrapper"
        >
          <Flex className="icon-wrap">
            {typeof icon === 'string' ? (
              <Image src={icon} maxHeight="24px" maxWidth="24px" />
            ) : (
              icon
            )}
          </Flex>
          <AnimatePresence>
            {drawerOpen && (
              <Flex width="180px" justifyContent="space-between">
                <Text
                  ml={4}
                  color={color}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, pointerEvents: 'none' }}
                >
                  {title}
                </Text>
                {subMenu && subMenu.length ? (
                  <ChevronDown onClick={() => setShowSubMenu((prevState) => !prevState)} />
                ) : null}
              </Flex>
            )}
          </AnimatePresence>
        </Flex>
        {!drawerOpen && !isTabletOrMobile && (
          <Tooltip bg={tooltipBg || 'blue.600'} className="sidebar-tooltip">
            <Text fontSize={13} color={tooltipColor || 'white'}>
              {title}
            </Text>
          </Tooltip>
        )}
      </MenuItem>
      {drawerOpen && showSubMenu && (
        <Flex ml={5} pl={5} flexDirection="column" bg="blue.600">
          {subMenu &&
            subMenu.map((subMenuItem) => (
              <MenuItem
                replace={true}
                height="30px"
                key={subMenuItem.to}
                to={subMenuItem.to}
                onClick={() => {
                  if (closeOnNavigate && drawerOpen) {
                    toggleDrawer()
                    setShowSubMenu((prevState) => !prevState)
                  }
                }}
              >
                <Text
                  ml={5}
                  color="gray.300"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, pointerEvents: 'none' }}
                >
                  {subMenuItem.title}
                </Text>
              </MenuItem>
            ))}
        </Flex>
      )}
    </>
  )
}

export default SideBarItem
