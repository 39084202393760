import * as React from 'react'

type NewsLogo = React.SVGProps<SVGSVGElement> & {
  iconColor?: React.SVGAttributes<SVGPathElement>['fill']
}

const NewsLogo = ({ iconColor = '#D1E7FF', ...props }: NewsLogo): JSX.Element => {
  return (
    <svg
      width="196"
      height="196"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <circle cx="98" cy="98" r="90" fill={iconColor} />
      </g>
      <path
        d="M114.187 120.107V70.4082C114.185 69.4891 113.818 68.6083 113.168 67.9584C112.519 67.3084 111.638 66.9421 110.719 66.9395H71.4062C70.487 66.9421 69.6063 67.3084 68.9563 67.9584C68.3064 68.6083 67.9401 69.4891 67.9374 70.4082V121.283C67.942 122.815 68.5525 124.283 69.6358 125.366C70.719 126.449 72.1868 127.06 73.7187 127.064H121.125"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M121.125 127.061C119.285 127.061 117.52 126.33 116.219 125.029C114.918 123.728 114.187 121.963 114.187 120.123V78.498H124.593C125.513 78.498 126.396 78.8635 127.046 79.514C127.697 80.1645 128.062 81.0468 128.062 81.9668V120.123C128.062 121.963 127.331 123.728 126.03 125.029C124.729 126.33 122.965 127.061 121.125 127.061Z"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M95.6874 78.498H104.937"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.6874 87.752H104.937"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1873 97.002H104.937"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1873 106.246H104.937"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1873 115.494H104.937"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.4373 88.5605H77.1873C76.9719 88.5605 76.7652 88.4749 76.6128 88.3226C76.4604 88.1702 76.3748 87.9635 76.3748 87.748V78.498C76.3748 78.2826 76.4604 78.0759 76.6128 77.9235C76.7652 77.7711 76.9719 77.6855 77.1873 77.6855H86.4373C86.6528 77.6855 86.8595 77.7711 87.0119 77.9235C87.1642 78.0759 87.2498 78.2826 87.2498 78.498V87.748C87.2498 87.9635 87.1642 88.1702 87.0119 88.3226C86.8595 88.4749 86.6528 88.5605 86.4373 88.5605Z"
        stroke="#316FB4"
        strokeWidth="3"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="196"
          height="196"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default NewsLogo
