import { Center, Flex, useMediaQuery } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { H3, H4, H5, Text } from 'typography'
import { responsiveWidth } from '../../constants'

export type Slide = {
  logo: React.ReactElement
  title: string
  subTitle: string
}

type SliderProps = {
  activeIndex: number
  slides: Slide[]
}

const BackgroundImage = styled.div`
  // terrible, I know. Getting this to resize appropriately has been a bit of a pain.
  background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 720 1066' fill='none' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet'%0A%3E%3Cpath d='M98 46C98 20.5949 118.595 0 144 0H720V92H144C118.595 92 98 71.4051 98 46Z' fill='%236F9ACA' /%3E%3Cpath d='M622 414C622 439.405 601.405 460 576 460L0 460L8.0429e-06 368L576 368C601.405 368 622 388.595 622 414Z' fill='%236F9ACA' /%3E%3Cpath d='M346 138C346 112.595 366.595 92 392 92H720V184H392C366.595 184 346 163.405 346 138Z' fill='%239FBCDC' /%3E%3Cpath d='M170 599C170 573.595 190.595 553 216 553H720V645H216C190.595 645 170 624.405 170 599Z' fill='%239FBCDC' /%3E%3Cpath d='M284 230C284 204.595 304.595 184 330 184H720V276H330C304.595 276 284 255.405 284 230Z' fill='%23145194' /%3E%3Cpath d='M436 507C436 532.405 415.405 553 390 553L0 553L8.0429e-06 461L390 461C415.405 461 436 481.595 436 507Z' fill='%23145194' /%3E%3Cpath d='M82 321C82 295.595 102.595 275 128 275H481C506.405 275 527 295.595 527 321C527 346.405 506.405 367 481 367H128C102.595 367 82 346.405 82 321Z' fill='%23225EA0' /%3E%3Cpath d='M214 138C214 112.595 234.595 92 260 92C285.405 92 306 112.595 306 138C306 163.405 285.405 184 260 184C234.595 184 214 163.405 214 138Z' fill='%239FBCDC' /%3E%3Cpath d='M36 599C36 573.595 56.5949 553 82 553C107.405 553 128 573.595 128 599C128 624.405 107.405 645 82 645C56.5949 645 36 624.405 36 599Z' fill='%239FBCDC' /%3E%3Cpath d='M602 322C602 296.595 622.595 276 648 276C673.405 276 694 296.595 694 322C694 347.405 673.405 368 648 368C622.595 368 602 347.405 602 322Z' fill='%23225EA0' /%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 100%;
`

const SliderContent: React.FC<SliderProps> = ({ activeIndex, slides }) => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)

  return (
    <Flex width="100%">
      {slides.map(({ title, subTitle, logo }, index) => (
        <Center key={title} display={index === activeIndex ? 'flex' : 'none'} width="100%">
          <Flex
            position="fixed"
            textAlign="center"
            alignItems="center"
            justifyContent="flex-start"
            flexDir="column"
            px={activeIndex === 2 || activeIndex === 4 ? '10rem' : 12}
            color="white"
            height={isTabletOrMobile ? '450px' : '500px'}
          >
            <Flex mb={12}>{logo}</Flex>
            {isTabletOrMobile ? (
              <H5 mb={8} mt={2} fontWeight="bold">
                {title}
              </H5>
            ) : (
              <H3 fontWeight="bold" mt={12} mb={8}>
                {title}
              </H3>
            )}
            {isTabletOrMobile ? (
              <Text fontWeight="bold" fontSize="sm">
                {subTitle}
              </Text>
            ) : (
              <H4 fontWeight="bold">{subTitle}</H4>
            )}
          </Flex>
          <BackgroundImage />
        </Center>
      ))}
    </Flex>
  )
}

export default SliderContent
