import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import styled from '@emotion/styled'
import ReactMarkdown from 'react-markdown'

export const Wrapper = styled(Box)`
  p,
  div,
  blockquote,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0.5em;
    margin-inline-end: 0.5em;
  }

  marquee {
    display: inline-block;
  }

  blockquote {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  q {
    display: inline;
  }

  q::before {
    content: open-quote;
  }

  q::after {
    content: close-quote;
  }

  hr {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    border-style: inset;
    border-width: 1px;
  }

  /* heading elements */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }

  h5 {
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }

  h6 {
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
  }

  /* tables */

  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tfoot {
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
  }

  table > tr {
    vertical-align: middle;
  }

  col {
    display: table-column;
  }

  colgroup {
    display: table-column-group;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  td,
  th {
    display: table-cell;
    vertical-align: inherit;
  }

  th {
    font-weight: bold;
  }

  caption {
    display: table-caption;
    text-align: center;
  }

  /* lists */

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  li {
    display: list-item;
    text-align: match-parent;
  }

  ::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    white-space: pre;
    text-transform: none;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ol ol ul,
  ol ul ul,
  ul ol ul,
  ul ul ul {
    list-style-type: square;
  }

  dd {
    display: block;
    margin-inline-start: 40px;
  }

  dl {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  dt {
    display: block;
  }

  ol ul,
  ul ol,
  ul ul,
  ol ol {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`

export default function ({ text, ...props }: BoxProps & { text: string }): JSX.Element {
  return (
    <Wrapper {...props}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </Wrapper>
  )
}
