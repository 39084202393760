import { Flex, FlexProps } from '@chakra-ui/react'
import { Banner, UnderConstruction } from 'components'
import * as React from 'react'
import { Helmet } from 'react-helmet'

type PageWrapProps = FlexProps & {
  title?: string
  hasBanner?: boolean
  isUnderConstruction?: boolean
  hasBackButton?: boolean
}

const PageWrap: React.FC<PageWrapProps> = ({
  children,
  title,
  hasBanner,
  isUnderConstruction,
  hasBackButton,
  ...rest
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <> {title && hasBanner && <Banner title={title} hasBackButton={hasBackButton} />}</>
      <Flex {...rest}>
        {isUnderConstruction && <UnderConstruction />}
        {children}
      </Flex>
    </>
  )
}

PageWrap.defaultProps = {
  flex: 1,
  padding: [4, 6],
  height: '100%',
  flexDir: 'column',
  minHeight: '100vh',
  align: 'flex-start',
  justify: 'flex-start',
  bgColor: 'white',
  hasBanner: false,
  width: '100%'
}

export default PageWrap
