import * as React from 'react'

type PrivateLogo = React.SVGProps<SVGSVGElement> & {
  iconColor?: React.SVGAttributes<SVGPathElement>['fill']
}

const PrivateLogo = ({ iconColor = '#D1E7FF', ...props }: PrivateLogo): JSX.Element => {
  return (
    <svg
      width="196"
      height="196"
      viewBox="0 0 196 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <circle cx="98" cy="98" r="90" fill={iconColor} />
      </g>
      <path
        d="M109.562 91.0625V77.332C109.562 74.2655 108.344 71.3245 106.176 69.1561C104.007 66.9877 101.066 65.7695 97.9998 65.7695C94.9332 65.7695 91.9922 66.9877 89.8238 69.1561C87.6554 71.3245 86.4373 74.2655 86.4373 77.332V91.0625"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.187 91.0605H81.8125C77.981 91.0605 74.875 94.1666 74.875 97.998V123.436C74.875 127.267 77.981 130.373 81.8125 130.373H114.187C118.019 130.373 121.125 127.267 121.125 123.436V97.998C121.125 94.1666 118.019 91.0605 114.187 91.0605Z"
        stroke="#316FB4"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="196"
          height="196"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default PrivateLogo
