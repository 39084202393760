import { theme as chakraTheme } from '@chakra-ui/react'
import images from './images'

// color palettes generated in https://smart-swatch.netlify.app/ with Gallop blue (#316FB4) as the main

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Montserrat, sans-serif',
    mono: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    brand: {
      50: '#e3f3ff',
      100: '#bfd9f4',
      200: '#99bde7',
      300: '#72a3da',
      400: '#4b89ce',
      500: '#316FB4',
      600: '#24578d',
      700: '#173e66',
      800: '#082540',
      900: '#000d1c',
      1000: 'rgba(50,110,180,0.2)'
    },
    // brand used to be based off of #222222
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    }
  },
  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
  opaqueBlue: 'rgba(49, 111, 180, 0.2)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

export { theme, images }
