import { ApolloError } from '@apollo/client'
import { UseToastOptions } from '@chakra-ui/react'
import get from 'lodash/get'
import { ReactNode } from 'react'
import { io } from 'socket.io-client'
import { ERROR_TOAST, STRAPI_USER_STORAGE_KEY, WARNING_TOAST } from '../constants'
/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = (): string | null => {
  const localUser = localStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export function formatError({ response }: { response: any }): string {
  return get(response, 'data.message[0].messages[0].message', 'An unknown error has occured.')
}

/**
 * function to return a handle errors returned from GraphQL queries/mutations.
 * @param message error message returned and destructured
 * @param toast useToast from Chakra
 * @param description optional *user friendly* message. Recommended
 * not using the graphQL error message.
 * recommended usage:
 * onError: (e) => handleErrors(e, toast, 'fetching your data')
 */

export const handleErrors = (
  // prettier-ignore
  { message }: ApolloError | Error,
  toast: (props: UseToastOptions) => ReactNode | void,
  description?: string
): ReactNode | void => {
  if (message.includes('403')) {
    return toast({ description: message, ...WARNING_TOAST })
  }
  return toast({
    description: description
      ? `Something went wrong with ${description}.`
      : message.replace('GraphQL error:', '').trim(),
    ...ERROR_TOAST
  })
}

export type Snippet = {
  publishedAt: string
  channelId: string
  title: string
  description: string
  thumbnails: {
    default: {
      url: string
      width: string
      height: string
    }
    medium: {
      url: string
      width: string
      height: string
    }
    high: {
      url: string
      width: string
      height: string
    }
  }
  channelTitle: string
  liveBroadcastContent: string
  publishTime: string
}

export type Item = {
  kind: string
  etag: string
  id: {
    kind: string
    videoId: string
  }
  snippet: Snippet
}

export type Items = Array<Item>
export interface IData {
  kind: string
  etag: string
  nextPageToken: string
  regionCode: string
  pageInfo: {
    totalResults: number
    resultsPerPage: number
  }
  items: Items
}

export const dateFormat = (date: Date | string): string => {
  //Current installed version of typescript the DateTimeFormatOptions doesn't have the prorperties '{ dateStyle: string; timeStyle: string; }'
  const options: any = { dateStyle: 'long', timeStyle: 'short' }
  return new Intl.DateTimeFormat('en-GB', options).format(new Date(date))
}

// cater for international numbers
// prettier-ignore
export const phoneNumberRegex = /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/g

export const isValidIdNumber = (idNumber: string): { error: boolean; msg: string } => {
  idNumber = idNumber.trim()
  const numbersRegex = /^\d+$/
  const isNumbers = numbersRegex.test(idNumber)
  if (!isNumbers) {
    return { error: !0, msg: 'An ID number must only contain numbers' }
  }
  if (!(idNumber.length === 13)) {
    return { error: !0, msg: 'An ID number has to have 13 digits' }
  }
  // prettier-ignore
  const fullRegex = /([0-9][0-9])(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))([0-9])([0-9]{3})([0-9])([0-9])([0-9])/
  const isFine = fullRegex.test(idNumber)
  if (!isFine) {
    return { error: !0, msg: 'Please enter a valid ID' }
  }
  const isValidId = LuhnAlgorithm(idNumber)
  if (isValidId) {
    return { error: !1, msg: '' }
  }
  return { error: !0, msg: 'Please enter a valid ID' }
}

const LuhnAlgorithm = (inputNumber: string): boolean => {
  inputNumber = inputNumber.trim()
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
  let len = inputNumber.length
  let bit = 1
  let sum = 0
  let val
  while (len) {
    val = parseInt(inputNumber.charAt(--len), 10)
    bit = bit ^ 1
    sum += bit === 1 ? arr[val] : val
  }
  return Boolean(sum && sum % 10 === 0)
}

export const socket = io(process.env.REACT_APP_API_HOST || '', {
  autoConnect: false
})

export * from './helper'
