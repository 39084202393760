import * as React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex
} from '@chakra-ui/react'
import { images } from 'theme'
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
  WorkplaceIcon
} from 'react-share'

type InviteDataProps = {
  isOpen: any
  setIsOpen: any
  shareUrl: string
}

function SocialShareModal({ isOpen, setIsOpen, shareUrl }: InviteDataProps): JSX.Element {
  const onClose = () => setIsOpen(false)
  const closeUrl = images.closeModal
  return (
    <>
      <Modal isCentered isOpen={isOpen} size="sm" onClose={onClose} motionPreset="slideInRight">
        <ModalOverlay />
        <ModalContent height="350px">
          <ModalCloseButton w={'39px'} h={'39px'}>
            <img src={closeUrl} alt="back arrow" />
          </ModalCloseButton>
          <ModalBody>
            <Flex
              position="absolute"
              ml="29.27%"
              mr="29.35%"
              mb="23px"
              justifyContent="center"
              background="#FFFFFF"
              color="#316FB4"
              fontWeight="bold"
              fontFamily="Muli,sans-serif"
            >
              Share Link
            </Flex>
            <Flex
              position="absolute"
              mt={10}
              w="90%"
              h="280px"
              display="inline"
              justifyContent="space-between"
              background="#FFFFFF"
            >
              <EmailShareButton url={shareUrl}>
                <EmailIcon round size={40} />
              </EmailShareButton>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon round size={40} />
              </FacebookShareButton>
              <FacebookShareButton url={shareUrl}>
                <FacebookMessengerIcon round size={40} />
              </FacebookShareButton>
              <HatenaShareButton url={shareUrl}>
                <HatenaIcon round size={40} />
              </HatenaShareButton>
              <InstapaperShareButton url={shareUrl}>
                <InstapaperIcon round size={40} />
              </InstapaperShareButton>
              <LineShareButton url={shareUrl}>
                <LineIcon round size={40} />
              </LineShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon round size={40} />
              </LinkedinShareButton>
              <LivejournalShareButton url={shareUrl}>
                <LivejournalIcon round size={40} />
              </LivejournalShareButton>
              <MailruShareButton url={shareUrl}>
                <MailruIcon round size={40} />
              </MailruShareButton>
              <OKShareButton url={shareUrl}>
                <OKIcon round size={40} />
              </OKShareButton>
              <PocketShareButton url={shareUrl}>
                <PocketIcon round size={40} />
              </PocketShareButton>
              <RedditShareButton url={shareUrl}>
                <RedditIcon round size={40} />
              </RedditShareButton>
              <TelegramShareButton url={shareUrl}>
                <TelegramIcon round size={40} />
              </TelegramShareButton>
              <TumblrShareButton url={shareUrl}>
                <TumblrIcon round size={40} />
              </TumblrShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon round size={40} />
              </TwitterShareButton>
              <ViberShareButton url={shareUrl}>
                <ViberIcon round size={40} />
              </ViberShareButton>
              <VKShareButton url={shareUrl}>
                <VKIcon round size={40} />
              </VKShareButton>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon round size={40} />
              </WhatsappShareButton>
              <WorkplaceShareButton url={shareUrl}>
                <WorkplaceIcon round size={40} />
              </WorkplaceShareButton>
            </Flex>
            <ModalFooter>
              <Button
                mt="75%"
                onClick={() => setIsOpen(false)}
                w={['234px', '316px']}
                colorScheme="blue"
                borderRadius="24px"
              >
                CLOSE
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
export default SocialShareModal
